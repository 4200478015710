<template>
  <div>
    <h1 class="text-left">Bulk import</h1>
    <p>
      This page can be used to bulk import companies and users to the system.<br />
      The process is to download a CSV file, fill it out with the necessary information for the items to import, and upload the file here again.<br />
      <br />
      Users are created with no password. To be able to login, either a manager will need to set a password for them or the
      <strong>Send mail to reset password</strong> button should be used.<br />
      <br />
      Companies should be created first, so they can be specified when importing users.
    </p>

    <!-- Buttons to download CSV templates -->
    <div>
      <v-btn @click="downloadCompanyCsv" depressed>
        <v-icon left>mdi-download</v-icon>
        Download CSV for company import
      </v-btn>
      <v-btn @click="downloadUserCsv" depressed class="ml-2">
        <v-icon left>mdi-download</v-icon>
        Download CSV for user import
      </v-btn>
    </div>

    <!-- Upload completed CSV -->
    <v-row class="mt-4">
      <v-col cols="12" md="3">
        <v-file-input @change="fileUploaded" accept=".csv" v-model="csvFile" single-line dense outlined label="Click here to upload filled-out CSV" />
      </v-col>
    </v-row>

    <!-- Loader -->
    <div v-if="loading">
      <v-progress-linear height="5" indeterminate class="my-2" />
    </div>

    <!-- Alert for errors -->
    <v-alert type="error" v-if="error">
      {{ error }}
    </v-alert>

    <!-- Import details for companies -->
    <div v-if="companies">
      <div v-if="!companiesImported">
        Ready to import {{ companies.length }} companies.
        <v-btn @click="importCompanies" depressed class="ml-2" :disabled="error || loading ? true : false">
          <v-icon left>mdi-upload</v-icon>
          Import companies
        </v-btn>
      </div>
      <v-alert type="success" v-else>{{ companies.length }} companies imported successfully.</v-alert>
      <v-data-table
        dense
        class="mt-4"
        :items="companies"
        :headers="companyHeaders.map((x) => ({ text: x.toUpperCase(), value: x }))"
        disable-pagination
        hide-default-footer
      >
        <template #[`item.enabled`]="{ item }">
          <v-simple-checkbox :ripple="false" :value="item.enabled" />
        </template>
      </v-data-table>
    </div>

    <!-- Import details for users -->
    <div v-if="users">
      <div v-if="!usersImported">
        Ready to import {{ users.length }} users.
        <v-checkbox v-model="importUsersWithPassword" dense label="Create password and send email to new users" />
        <v-btn @click="importUsers" depressed class="ml-2" :disabled="error || loading ? true : false">
          <v-icon left>mdi-upload</v-icon>
          Import users
        </v-btn>
      </div>
      <v-alert type="success" v-else>{{ importedUsers.length }} users imported successfully.</v-alert>
      <v-alert type="warning" class="mt-2" v-if="duplicateUsers.length">{{ duplicateUsers.length }} duplicate users will be ignored</v-alert>
      <v-data-table
        dense
        class="mt-4"
        :items="users"
        :headers="userTableHeaders"
        :item-class="x => x.imported ? 'green' : ''"
        disable-pagination
        hide-default-footer
      >
        <template #[`item.enabled`]="{ item }">
          <v-simple-checkbox :ripple="false" :value="item.enabled" />
        </template>
        <template #[`item.prerelease`]="{ item }">
          <v-simple-checkbox :ripple="false" :value="item.prerelease" />
        </template>
        <template #[`item.duplicate`]="{ item }">
          <v-alert dense v-if="item.duplicate" type="warning">Already exists</v-alert>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import apiService from '@/services/apiService';

export default {
  name: 'bulk-import',
  data: () => ({
    csvFile: null,
    loading: false,
    error: null,
    companies: null,
    companiesImported: false,
    users: null,
    usersImported: false,
    importUsersWithPassword: false,
    companyHeaders: ['name', 'department', 'accountNumber', 'country', 'license', 'enabled'],
    userHeaders: ['name', 'email', 'company', 'department', 'phone', 'role', 'prerelease', 'enabled'],
  }),
  computed: {
    userTableHeaders() {
      const headers = this.userHeaders.map((x) => ({ text: x.toUpperCase(), value: x }));

      headers.push({ text: '', value: 'duplicate' });

      return headers;
    },
    duplicateUsers() {
      return this.users.filter(y => y.duplicate);
    },
    importedUsers() {
      return this.users.filter(y => y.imported);
    }
  },
  methods: {
    downloadUserCsv() {
      this.$util.downloadAsFile(this.userHeaders.join(';') + '\nHans Hansen;hh@example.invalid;Cubic;;;Manager;0;1', 'Users.csv');
    },
    downloadCompanyCsv() {
      this.$util.downloadAsFile(this.companyHeaders.join(';') + '\nNew company;;300200;DK;Standard;1', 'Companies.csv');
    },
    async fileUploaded() {
      if (this.csvFile) {
        this.users = null;
        this.companies = null;
        this.usersImported = false;
        this.companiesImported = false;
        this.error = null;

        try {
          const fileContent = await this.csvFile.text();
          let lines = fileContent.replaceAll('\r', '').split('\n');
          if (lines[0].toLowerCase() === this.companyHeaders.join(';').toLowerCase()) {
            await this.parseCompanyCsv(lines);
          } else if (lines[0].toLowerCase() === this.userHeaders.join(';').toLowerCase()) {
            await this.parseUserCsv(lines);
          } else {
            throw 'CSV header not recognized';
          }
          this.error = null;
        } catch (e) {
          this.error = e;
        } finally {
          this.loading = false;
        }
      }
    },
    async parseUserCsv(lines) {
      lines.shift();

      var users = lines
        .filter((x) => x)
        .map((x) => _.zipObject(this.userHeaders, x.split(';').map(x => x.trim())))
        .filter((x) => x.email);

      const knownUsers = await apiService.getUsers(true);
      const knownCompanies = await apiService.getCompanies(true);

      for (const user of users) {
        if (knownUsers.some((x) => x.email.toLowerCase() === user.email.toLowerCase())) {
          user.duplicate = true;
        }

        const company = knownCompanies.find((x) => x.name === user.company && (x.department === user.department || (!x.department && !user.department)));
        if (!company) {
          throw `Company "${user.company}", department "${user.department} not found`;
        }

        switch (user.role.toLowerCase()) {
          case 'manager':
          case 'sitemanager':
          case 'admin':
          case '':
            break;

          default:
            throw `User role must be one of "manager", "sitemanager", "admin" or blank. "${user.role}" is not valid.`;
        }

        user.companyId = company.id;
        user.enabled = user.enabled === '1';
        user.prerelease = user.prerelease === '1';
        user.imported = false;
      }

      this.users = users;
    },
    async parseCompanyCsv(lines) {
      lines.shift();

      var companies = lines.filter((x) => x).map((x) => _.zipObject(this.companyHeaders, x.split(';').map(x => x.trim())));

      const knownCompanies = await apiService.getCompanies(true);
      const licenses = await apiService.getLicenses(true);

      for (const company of companies) {
        if (knownCompanies.some((x) => x.name === company.name && (x.department === company.department || (!x.department && !company.department)))) {
          throw `Company "${company.name}", department "${company.department}" already exists`;
        }

        const license = licenses.find((x) => x.name === company.license);

        if (!license) {
          throw `License "${company.license}" not found`;
        }

        company.licenseId = license.id;

        company.enabled = company.enabled === '1';
      }

      this.companies = companies;
    },
    async importCompanies() {
      this.loading = true;

      try {
        for (const company of this.companies) {
          await apiService.createOrEditCompany({
            name: company.name,
            accountNumber: company.accountNumber,
            department: company.department,
            country: company.country,
            licenseId: company.licenseId,
            disabled: !company.enabled,
          });
        }

        this.companiesImported = true;
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
      }
    },
    async importUsers() {
      this.loading = true;

      try {
        for (const user of this.users.filter(y => !y.duplicate)) {
          await apiService.createOrEditUser({
            name: user.name,
            disabled: !user.enabled,
            companyId: user.companyId,
            phoneNumber: user.phone,
            email: user.email,
            roles: user.role ? [user.role.toLowerCase()] : null,
            prereleaseEnabled: user.prerelease,
            noPassword: !this.importUsersWithPassword,
            sendWelcome: user.enabled,
          });

          user.imported = true;
        }

        this.usersImported = true;
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"text-left"},[_vm._v("Bulk import")]),_vm._m(0),_c('div',[_c('v-btn',{attrs:{"depressed":""},on:{"click":_vm.downloadCompanyCsv}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v(" Download CSV for company import ")],1),_c('v-btn',{staticClass:"ml-2",attrs:{"depressed":""},on:{"click":_vm.downloadUserCsv}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v(" Download CSV for user import ")],1)],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-file-input',{attrs:{"accept":".csv","single-line":"","dense":"","outlined":"","label":"Click here to upload filled-out CSV"},on:{"change":_vm.fileUploaded},model:{value:(_vm.csvFile),callback:function ($$v) {_vm.csvFile=$$v},expression:"csvFile"}})],1)],1),(_vm.loading)?_c('div',[_c('v-progress-linear',{staticClass:"my-2",attrs:{"height":"5","indeterminate":""}})],1):_vm._e(),(_vm.error)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),(_vm.companies)?_c('div',[(!_vm.companiesImported)?_c('div',[_vm._v(" Ready to import "+_vm._s(_vm.companies.length)+" companies. "),_c('v-btn',{staticClass:"ml-2",attrs:{"depressed":"","disabled":_vm.error || _vm.loading ? true : false},on:{"click":_vm.importCompanies}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-upload")]),_vm._v(" Import companies ")],1)],1):_c('v-alert',{attrs:{"type":"success"}},[_vm._v(_vm._s(_vm.companies.length)+" companies imported successfully.")]),_c('v-data-table',{staticClass:"mt-4",attrs:{"dense":"","items":_vm.companies,"headers":_vm.companyHeaders.map(function (x) { return ({ text: x.toUpperCase(), value: x }); }),"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"ripple":false,"value":item.enabled}})]}}],null,true)})],1):_vm._e(),(_vm.users)?_c('div',[(!_vm.usersImported)?_c('div',[_vm._v(" Ready to import "+_vm._s(_vm.users.length)+" users. "),_c('v-checkbox',{attrs:{"dense":"","label":"Create password and send email to new users"},model:{value:(_vm.importUsersWithPassword),callback:function ($$v) {_vm.importUsersWithPassword=$$v},expression:"importUsersWithPassword"}}),_c('v-btn',{staticClass:"ml-2",attrs:{"depressed":"","disabled":_vm.error || _vm.loading ? true : false},on:{"click":_vm.importUsers}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-upload")]),_vm._v(" Import users ")],1)],1):_c('v-alert',{attrs:{"type":"success"}},[_vm._v(_vm._s(_vm.importedUsers.length)+" users imported successfully.")]),(_vm.duplicateUsers.length)?_c('v-alert',{staticClass:"mt-2",attrs:{"type":"warning"}},[_vm._v(_vm._s(_vm.duplicateUsers.length)+" duplicate users will be ignored")]):_vm._e(),_c('v-data-table',{staticClass:"mt-4",attrs:{"dense":"","items":_vm.users,"headers":_vm.userTableHeaders,"item-class":function (x) { return x.imported ? 'green' : ''; },"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"ripple":false,"value":item.enabled}})]}},{key:"item.prerelease",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"ripple":false,"value":item.prerelease}})]}},{key:"item.duplicate",fn:function(ref){
var item = ref.item;
return [(item.duplicate)?_c('v-alert',{attrs:{"dense":"","type":"warning"}},[_vm._v("Already exists")]):_vm._e()]}}],null,true)})],1):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" This page can be used to bulk import companies and users to the system."),_c('br'),_vm._v(" The process is to download a CSV file, fill it out with the necessary information for the items to import, and upload the file here again."),_c('br'),_c('br'),_vm._v(" Users are created with no password. To be able to login, either a manager will need to set a password for them or the "),_c('strong',[_vm._v("Send mail to reset password")]),_vm._v(" button should be used."),_c('br'),_c('br'),_vm._v(" Companies should be created first, so they can be specified when importing users. ")])}]

export { render, staticRenderFns }